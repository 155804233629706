import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import store from '@/store';
import { BootstrapVue } from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2';
import VueToastr from 'vue-toastr';
import VueCookies from 'vue-cookies';
import { use } from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import VueBarcode from 'vue-barcode';
import ECharts from 'vue-echarts';
import moment from 'moment';
import AOS from 'aos';
import VueAzureMaps from 'vue-azure-maps'

// eslint-disable-next-line import/no-extraneous-dependencies
import dotenv from 'dotenv';
import myI18n from '@/locales';
import App from './App.vue';
import router from './router';
import './design/scss/app.scss';

import './design/scss/main.scss';
import 'aos/dist/aos.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cross-env';

dotenv.config({ path: `.env.${process.env.NODE_ENV}` });

require('vue-tour/dist/vue-tour.css');
const numeral = require('numeral');

Vue.use(VueClipboard);
VueClipboard.config.autoSetContainer = true;
Vue.use(BootstrapVue);
Vue.use(VueCookies);

Vue.use(VueToastr, {
    defaultTimeout: 3000,
    defaultPosition: 'toast-top-right',
    defaultClassNames: ['animate__animated ', 'animate__bounceInRight'],
});

Vue.use(VueAzureMaps, {
    key: 'EgHzHkRthRqctbvGMnFQHes3NN8DAHpVhssjheTZZkZheL2DScXdJQQJ99AIACYeBjFK9zUBAAAgAZMPJ1qS',
  })

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDpekgSK87kPxDwraJU8jNy8leJc7gQzzc',
        libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
});
Vue.mixin({
    methods: {
        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        TruncateToDecimals2(num, type, dec = 4) {
            //   if (!dec) dec = 2;
            if (typeof num === 'number') {
                const numberFormat = parseFloat(num.toFixed(10));
                const d = numberFormat.toString().split('.');
                if (type === 1) {
                    return `${numeral(d[0]).format(0, 0)}`;
                }
                return `${numeral(d[0]).format(0, 0)}${
                    d[1] ? `.${d[1].slice(0, dec)}` : ''
                }`;
            }
            return 0;
        },
        roundUpFormat(num) {
            const myNumber = Number(num);
            if (typeof myNumber === 'number') {
                return Math.ceil(myNumber).toLocaleString('en-US');
            }
            return 0;
        },
        getDateTime(unix) {
            return moment(unix)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY, HH:mm:ss');
        },
        getDateTime3(date) {
            const unix = Date.parse(date);
            return moment(unix)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY, HH:mm:ss');
        },
        getDateTime4(unix) {
            return moment(unix * 1000)
                .utcOffset('+00:00')
                .format('DD/MM/YYYY');
        },
        messageErrorToastr(message) {
            this.$toastr.e(
                message.replace('Returned error: execution reverted: ', ''),
                'Failed',
            );
        },
        isNaN(x) {
            // eslint-disable-next-line no-self-compare
            return x !== x;
        },
        toPlainString(num) {
            return `${+num}`.replace(
                /(-?)(\d*)\.?(\d*)e([+-]\d+)/,
                function (a, b, c, d, e) {
                    return e < 0
                        ? `${b}0.${Array(1 - e - c.length).join(0)}${c}${d}`
                        : b + c + d + Array(e - d.length + 1).join(0);
                },
            );
        },
        getDateTime5(date) {
            const unix = Date.parse(date);
            return moment(unix).utcOffset('+00:00').format('DD/MM');
        },
    },
});

Vue.config.productionTip = false;
Vue.component('v-chart', ECharts);
Vue.component('barcode', VueBarcode);
use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);
new Vue({
    router,
    store,
    i18n: myI18n,
    render: (h) => h(App),
    mounted() {
        AOS.init();
    },
}).$mount('#app');
